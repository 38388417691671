//@ts-nocheck
import moment from "moment";
import {
  Equipment,
  Holidays,
  Planning,
  Shift,
} from "../../../../redux/apis/types";
import _groupBy from "lodash/groupBy";
import _ from "lodash";
import { SERVICES, get } from "../../../../redux/apis/global";
import { timeZoneMoment } from "../../../../redux/apis/utils";

export const base64toBlob = (data: string) => {
  // Cut the prefix `data:application/pdf;base64` from the raw base 64
  // const base64WithoutPrefix = data.substr(
  //   "data:application/pdf;base64,".length
  // );

  const bytes = atob(data);
  let length = bytes.length;
  let out = new Uint8Array(length);

  while (length--) {
    out[length] = bytes.charCodeAt(length);
  }

  return new Blob([out], { type: "application/pdf" });
};

export const getCombinedPoNumber = (data: Planning[]) => {
  const po_numbers = Array.from(
    new Set(data.map((x) => x.po_number).filter((x) => !!x))
  ).join("-");
  return po_numbers || "";
};

export const generateSubcontractorInvoiceRequestBody = (data: any) => {
  let invoice = {
    subcontractor_id: data.shifts[0].subcontractor_id || subcontractorId,
    invoice_number: data.invoice_number,
    invoice_date: moment(data.invoice_date).format("YYYY-MM-DD HH:mm"),
    currency: data.currency,
    vat: data.vat,
    total: data.total_amount,
    status: "generated",
    data: {
      work: data.shifts,
      vat: data.vat,
      total: data.total_amount,
      amount: data.amount,
    },
  };

  return invoice;
};

export const hasExtraHours = (planning: Planning[]) => {
  return (
    planning.filter((item) => !!item.extra_hours && item.extra_hours > 0)
      .length === planning.length
  );
};
export const generateInvoiceRequestBody = (
  data: any,
  equipmentState: Equipment[],
  creditInvoice: boolean
) => {
  const MULTIPLIER = creditInvoice ? -1 : 1;
  const equipmentRange = data.request_equipment_range;
  let invoice = _.cloneDeep(data);
  console.log("invoice : ", invoice);

  let totalInvoice = 0;

  for (let i = 0; i < invoice.work.length; i++) {
    const planningRates = invoice.work[i].planningRates;
    let total = 0;

    for (let j = 0; j < planningRates.length; j++) {
      const rate = getTotalSinglePlanningRate(planningRates[j]);
      total += rate;
      invoice.work[i].planningRates[j].total = rate * MULTIPLIER;
      invoice.work[i].planningRates[j].amount *= MULTIPLIER;
      if (invoice.work[i].planningRates[j].start instanceof moment) {
        invoice.work[i].planningRates[j].start =
          invoice.work[i].planningRates[j].start.format("DD-MM-YYYY HH:mm");
      } else {
        invoice.work[i].planningRates[j].start = moment(
          invoice.work[i].planningRates[j].start,
          "DD-MM-YYYY HH:mm"
        ).format("DD-MM-YYYY HH:mm");
      }

      if (invoice.work[i].planningRates[j].end instanceof moment) {
        invoice.work[i].planningRates[j].end =
          invoice.work[i].planningRates[j].end.format("DD-MM-YYYY HH:mm");
      } else {
        invoice.work[i].planningRates[j].end = moment(
          invoice.work[i].planningRates[j].end,
          "DD-MM-YYYY HH:mm"
        ).format("DD-MM-YYYY HH:mm");
      }
    }
    invoice.work[i].total = (total * MULTIPLIER).toFixed(2);
    totalInvoice += total;
  }

  let generatedEquipmentList: Array<any> = [];

  for (let i = 0; i < equipmentRange.length; i++) {
    // const totalRangeEquipment = getDateRangeEquipmentRates(equipmentRange[i]);
    const amount = Math.round(
      moment
        .duration(
          moment(equipmentRange[i].end).diff(moment(equipmentRange[i].start))
        )
        .asDays()
    );

    for (let j = 0; j < equipmentRange[i].equipment.length; j++) {
      const equipment = equipmentRange[i].equipment[j];
      generatedEquipmentList.push({
        start: moment(equipmentRange[i].start).format("YYYY-MM-DD"),
        end: moment(equipmentRange[i].end).format("YYYY-MM-DD"),
        amount: amount * MULTIPLIER,
        total: amount * equipment.quantity * equipment.price * MULTIPLIER,
        currency: invoice.currency,
        price: equipment.price * MULTIPLIER,
        description: equipment.description,
        quantity: equipment.quantity,
        equipmentName: equipmentState.find(
          (item) => item.id == equipment.equipment_id
        )?.name,
      });
    }
    totalInvoice += getTotalEquipmentRates(equipmentRange);
    // const equipmentList = equipmentRange[i].equipment.map((item) => ({
    //   ...item,
    //   start:
    // }))
  }

  if (generatedEquipmentList && generatedEquipmentList.length > 0) {
    invoice.equipment = {
      list: generatedEquipmentList,
      total: getTotalEquipmentRates(equipmentRange) * MULTIPLIER,
      currency: invoice.currency,
    };
  }

  // if (!invoice.vat) {
  //   invoice.vat = "N/A";
  // }
  invoice.totalExcl = (totalInvoice * MULTIPLIER).toFixed(2);
  invoice.totalIncl = (
    (totalInvoice +
      (totalInvoice * (invoice.vat !== "N/A" ? invoice.vat : 0)) / 100) *
    MULTIPLIER
  ).toFixed(2);

  invoice.invoice_date = moment(invoice.invoice_date).format(
    "YYYY-MM-DD HH:mm"
  );

  return invoice;
};
export const calculateTotalRate = (invoiceRates: any) =>
  invoiceRates.reduce((accumulator: number, object: any) => {
    return accumulator + object.totalRate;
  }, 0);

export const formatPrice = (
  value: number,
  currency: string,
  creditInvoice: boolean | undefined
) => {
  let price: any = parseFloat(value || 0);
  if (creditInvoice) {
    price = price * -1;
  }

  price = price.toFixed(2);

  if (!currency) return price;

  return `${price.replace(".", ",")} ${currency.toUpperCase()}`;
};

export const getHolidayByDate = (
  holidays: Array<Holidays>,
  planning: Planning
) => {
  return holidays.find(
    (item) =>
      moment(item.date).format("YYYY-MM-DD") ===
        moment(planning.start).format("YYYY-MM-DD") ||
      moment(item.date).format("YYYY-MM-DD") ===
        moment(planning.end).format("YYYY-MM-DD")
  );
};
export const getPlanningHolidays = (
  planning: Array<Planning>,
  holidaysMap: Array<any>
) => {
  const planningHolidays = planning.filter(
    (item) =>
      !!holidaysMap.find(
        (holidayItem) =>
          holidayItem.country === item.location?.country &&
          (moment(holidayItem.date).format("YYYY-MM-DD") ===
            moment(item.start).format("YYYY-MM-DD") ||
            moment(holidayItem.date).format("YYYY-MM-DD") ===
              moment(item.end).format("YYYY-MM-DD"))
      )
  );

  return planningHolidays;
};

export const generateDefaultShiftInvoice = (data: Shift[]) => {
  const subcontractorId = data[0]?.subcontractor_id;
  let shifts = _.cloneDeep(data);
  for (let i = 0; i < shifts.length; i++) {
    if (
      shifts[i].planning &&
      !!shifts[i].planning.branch_rate &&
      shifts[i].planning.branch_rate?.subcontractor_rates &&
      shifts[i].planning?.branch_rate.subcontractor_rates.length > 0 &&
      shifts[i].planning?.branch_rate?.subcontractor_rates?.find(
        (x) => x.subcontractor_id == subcontractorId
      )?.rate
    ) {
      // @ts-nocheck
      if (!shifts[i].purchase_cost) {
        shifts[i].purchase_cost =
          shifts[i].planning?.branch_rate?.subcontractor_rates?.find(
            (x) => x.subcontractor_id == subcontractorId
          )?.rate /
            100 +
          "";
      }
    }
  }

  return shifts;
};
export const generateInvoice = (
  planning: Array<Planning>,
  holidays: Array<Holidays>,
  currency: string,
  invoiceRateType: "Default Rate" | "Extra Rate"
) => {
  // const branch_id = planning[0].branch_id;
  // const location_ids = [
  //   ...new Set(planning.map((obj) => obj.location_id)),
  // ].join(",");

  // const invoiceRates = await get(`${SERVICES.RATE}/invoice`, undefined, {
  //   branch_id,
  //   location_ids,
  // });

  let serviceList = [];
  // const holidaysMap: string[] = holidays.map((item) =>
  //   moment(item.date).format("YYYY-MM-DD")
  // );
  const grouppedPlanning = _groupBy(
    planning,
    (item: Planning) => item.request_type + ":" + item.location_id.toString()
  );
  const values = Object.values(grouppedPlanning) as Array<Array<Planning>>;

  for (let i = 0; i < values.length; i++) {
    const planningHolidays = getPlanningHolidays(values[i], holidays);
    const planningHolidaysMapIds = planningHolidays.map((item) => item.id);

    let nestedValues = _.cloneDeep(values[i]);
    const service = nestedValues[0].request_type;
    const locationName = nestedValues[0].location?.name;

    if (planningHolidays.length > 0) {
      const holidaysData = getPLanningRates(
        planningHolidays,
        holidays,
        currency,
        invoiceRateType
      );
      serviceList.push({
        service: `Holidays - ${service}`,
        locationName,
        planningRates: holidaysData.planningRates,
        currency,
        isHoliday: true,
      });
    }

    // nestedValues = nestedValues.filter(
    //   (item) => !planningHolidaysMapIds.includes(item.id)
    // );

    if (nestedValues.length > 0) {
      const nestedPlanningValues = getPLanningRates(
        nestedValues,
        null,
        currency,
        invoiceRateType
      );

      serviceList.push({
        service,
        locationName,
        planningRates: nestedPlanningValues.planningRates,
        // description,
        currency,
        isHoliday: false,
      });
    }
  }

  return _.cloneDeep(serviceList.sort(isHoliday));
};

function isHoliday(a: any, b: any) {
  return b.isHoliday - a.isHoliday;
}

export const getTotalSinglePlanningRate = (
  planningSection: any,
  isDay?: boolean
) => {
  // console.log("planningSection.price : ", planningSection.price);
  const start =
    typeof planningSection.start === "string"
      ? moment(planningSection.start, "DD-MM-YYYY HH:mm")
      : planningSection.start;
  const end =
    typeof planningSection.end === "string"
      ? moment(planningSection.end, "DD-MM-YYYY HH:mm")
      : planningSection.end;
  const diff = moment.duration(moment(end).diff(moment(start)));
  const amount = isDay ? Math.round(diff.asDays()) : diff.asHours();
  return planningSection.price * planningSection.quantity * amount;
};
export const getTotalPlanningSectionRates = (
  planningSection: Array<any>,
  isDay?: boolean
) => {
  let total = 0;
  for (let i = 0; i < planningSection.length; i++) {
    total += getTotalSinglePlanningRate(planningSection[i], isDay);
  }
  return total;
};

export const getTotalEquipmentRates = (equipmentRange: Array<any>) => {
  if (!equipmentRange) return 0;
  let total = 0;
  for (let i = 0; i < equipmentRange.length; i++) {
    total += getDateRangeEquipmentRates(equipmentRange[i]);
  }
  return total;
};
export const getDateRangeEquipmentRates = (equipmentDateRange: any) => {
  if (!equipmentDateRange) return 0;
  let total = 0;

  const equipmentList = equipmentDateRange.equipment;
  const amount = Math.round(
    moment
      .duration(
        moment(equipmentDateRange.end).diff(moment(equipmentDateRange.start))
      )
      .asDays()
  );

  total += equipmentList.reduce(
    (acc: number, item: any) =>
      acc + item.quantity * parseFloat(item.price) * amount,
    0
  );

  return total;
};

export const getTotalInvoiceRate = (
  invoiceData: Array<any>,
  request_equipment_range: Array<any>,
  vat?: number,
  invoiceRateType: "Default Rate" | "Extra Rate"
) => {
  let totalNet = getTotalEquipmentRates(request_equipment_range as Array<any>);
  let totalVAT = 0;

  if (!invoiceData) return { totalNet, totalVAT };
  for (let i = 0; i < invoiceData.length; i++) {
    totalNet += getTotalPlanningSectionRates(
      invoiceData[i].planningRates,
      false
    );
  }

  if (!!vat) {
    totalVAT = (totalNet * vat) / 100;
  }
  return { totalNet, totalVAT };
};
export const calculatePlanningRates = (
  planning: Array<Planning>,
  branchRates: Array<any>
) => {};
export const getGroupedPlanning = (planning: Array<Planning>) => {
  const grouppedLocation = groupByKey(planning, "location_id");
  const keys = Object.keys(grouppedLocation);
  let structuredInvoice = [];
  for (let i = 0; i < keys.length; i++) {
    structuredInvoice.push({
      location: grouppedLocation[keys[i]][0].location,
      data: groupByKey(grouppedLocation[keys[i]], "request_type"),
    });
  }
  return structuredInvoice;
};

export const groupByKey = (planning: any, key: any) =>
  planning.reduce((group: any, item: any) => {
    const value = item[key];
    group[value] = group[value] ?? [];
    group[value].push(item);
    return group;
  }, {});

export const getPlanningExtraShiftRates = (
  planning: Array<Planning>,
  holidays: Array<Holidays> | null,
  currency: string
) => {
  let planningRates = [];

  for (let j = 0; j < planning.length; j++) {
    const planningObj = planning[j] as Planning;
    const shifts = planningObj.shifts.filter((x) => x.extra_rate == 1);
    const branchRate = planning[j].extra_branch_rate;

    for (let i = 0; i < shifts.length; i++) {
      const shift = shifts[i];
      const rate = branchRate?.rate || 0;
      const holidayRate = branchRate?.holiday_rate || 0;
      const amount = moment
        .duration(moment(shift.end).diff(moment(shift.start)))
        .asHours();

      let price = rate / 100;
      if (holidays) {
        price = (price * holidayRate) / 100;
      }

      const obj = {
        planningId: planningObj.id,
        start: timeZoneMoment(shift.start, planningObj.location?.timezone),
        end: timeZoneMoment(shift.end, planningObj?.location?.timezone),
        price,
        description: !!holidays
          ? getHolidayByDate(holidays, planningObj)?.name
          : "",
        amount,
        quantity: shift.agents.length,
        currency,
        branch_rate_id: branchRate?.id,
      };

      planningRates.push(obj);
    }
  }
  return { currency, planningRates };
};
export const getPLanningRates = (
  planning: Array<Planning>,
  holidays: Array<Holidays> | null,
  currency: string,
  invoiceRateType: "Default Rate" | "Extra Rate"
) => {
  if (invoiceRateType === "Extra Rate") {
    return getPlanningExtraShiftRates(planning, holidays, currency);
  }

  // console.log("invoiceRateType : ", invoiceRateType);
  let planningRates = [];

  for (let j = 0; j < planning.length; j++) {
    const planningObj = planning[j] as Planning;
    const branchRate = planning[j].branch_rate;
    // invoiceRateType === "Default Rate"
    //   ? planning[j].branch_rate
    //   : planning[j].extra_branch_rate;
    // console.log("branchRatess : ", branchRate);
    const rate = branchRate?.rate || 0;
    const holidayRate = branchRate?.holiday_rate || 0;
    const amount = moment
      .duration(moment(planningObj.end).diff(moment(planningObj.start)))
      .asHours();

    let price = rate / 100;
    if (holidays) {
      price = (price * holidayRate) / 100;
    }

    const obj = {
      planningId: planningObj.id,
      start: timeZoneMoment(planningObj.start, planningObj.location?.timezone),
      end: timeZoneMoment(planningObj.end, planningObj?.location?.timezone),
      price,
      description: !!holidays
        ? getHolidayByDate(holidays, planningObj)?.name
        : "",
      amount,
      quantity: planningObj.number_of_agents,
      currency,
      branch_rate_id: branchRate?.id,
    };
    // if (invoiceRateType === "Extra Rate") {
    //   obj.start = timeZoneMoment(
    //     planningObj.end,
    //     planningObj?.location?.timezone
    //   );
    //   // console.log("planningObj.extra_hours : ", planningObj.extra_hours);
    //   obj.end = timeZoneMoment(
    //     moment(planningObj.end)
    //       .add(planningObj.extra_hours, "hours")
    //       .format("YYYY-MM-DD HH:mm"),
    //     planningObj?.location?.timezone
    //   );
    //   obj.amount = planningObj.extra_hours;
    //   obj.quantity = planningObj.extra_number_of_agents;
    // }
    planningRates.push(obj);
  }
  return { currency, planningRates };
};
